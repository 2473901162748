import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide35/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide35/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide35/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide35/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide35/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo crear un pitch de ventas en simples pasos?
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La tasa promedio de éxito en cerrar acuerdos después de presentar
      propuestas o cotizaciones ronda el 47%, mientras que las tasas de
      conversión de ventas en diversas industrias tienden a situarse en el
      estrecho rango del 2.46% al 3.26%.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Generar nuevos clientes potenciales requiere un esfuerzo significativo.
    Comenzar la presentación suele ser el desafío principal, ya que captar la
    atención del prospecto es crucial para comunicar el valor de tu producto.
    <br /> <br />
    Por eso, en esta guía aprenderás a crear uno propio para tu empresa. De este
    modo, todos tus agentes de ventas estarán listos para convertir.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Analiza a tus clientes.",
      },
      {
        key: 2,
        text: "Desarrollar conceptos desde diferentes perspectivas.",
      },
      {
        key: 3,
        text: "Fomenta la colaboración entre equipos.",
      },
      {
        key: 4,
        text: "Evalúa y perfecciona tu discurso de ventas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
